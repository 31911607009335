header {
    border-radius: 14px;
    background: linear-gradient(180deg, #3D4152 0%, rgba(61, 65, 82, 0.00) 100%);
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    z-index: 3;
    .logo-red {
        display: none;
    }
    .hamburger {
        display: none;
        cursor: pointer;
        @media only screen and (max-width: 1023px) {
            display: flex;
        }
    }
    .mobile-navigation {
        display: none;
        position: fixed;
        inset: 0;
        background-color: white;
        z-index: 2;
        background-image: url('../../public/svg/common/header/mobile-tree.svg'), url('../../public/svg/common/header/mobile-trally.svg');
        background-position: right 4px top 40px, left 50px top 220px;
        background-repeat: no-repeat;
        &.active {
            @media only screen and (max-width: 1023px) {
                display: flex;
            }
        }
        .nav-list {
            width: 100%;
            .nav-list-item {
                a {
                    font-size: 14px;
                    
                    color: #3D4152;
                }
            }
        }
        .hamburger-close {
            cursor: pointer;
        }
        .enquiry-block-mobile {
            background-color: #FFF3ED;
            border-radius: 4px;
            background-image: url('../../public/svg/common/header/mobile-leaf.svg');
            background-repeat: no-repeat;
            background-position: top right;
            .enquiry-info {
                h4, p {
                    color: #FF6737;
                }
            }
            .enquiry-picture {
                max-width: 480px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .social-list {
            li {
                a {
                    img {
                        filter: brightness(0.5);
                    }
                }
            }
        }
    }
    .nav-right {
        .nav-list-item {
            a {
                font-size: 15px;
                font-weight: 600;
                &:hover {
                    color: #FF7B59;
                }
            }
        }
        @media only screen and (max-width: 1023px) {
            display: none;
        }
    }
    &.static-header {
        position: relative;
        inset: 0;
        background: #fff;
        .logo-red {
            display: flex;
        }
        .logo-default {
            display: none;
        }
        .hamburger {
            img {
                filter: invert(1);
            }
        }
        .nav-right {
            .nav-list-item {
                a {
                    color: #3D4152;
                    &:hover {
                        font-weight: 700;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 1023px) {
        position: relative;
        left: initial;
        right: initial;
        top: inherit;
    }
}